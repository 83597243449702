<template>
  <div class="home">
    <div class="container">
      <div class="top-left-panel">
        <div class="top-left-background">
          <img id="logo" alt="Logo Incobex" src="../assets/inco_name.svg">
        </div>
      </div>
      <div class="top-panel">
        <div class="group-container" :class="{ dev: !$layout.isDevMenu }">
          <div v-for="(group, index) in groupsWithProps" :key="index" class="group">
            <button
                @click="toggleWidget(index)"
                class="vertical-button"
                :class="{ active: group.isActive, horizontal: !$layout.isDevMenu, [`btn${index}`]: !$layout.isDevMenu }"
            >
              {{ group.name }}
            </button>
            <div v-show="group.isActive" class="widget">
              <component
                  :ref="group.ref"
                  :is="group.component"
                  v-bind="group.props"
                  v-on="group.events"
              ></component>
            </div>
          </div>
        </div>
        <ToolsPanel
            ref="toolPanel"
            :myCanvas="myCanvas"
            :selected-objects="selectedObjects"
            :isSelectedOne="isSelectedOne"
            :isSelected="isSelected"
            :groupCreationDisabled="groupCreationDisabled"
            @add-note="handleAddNote"
            @rotate-left="handleRotateLeft"
            @zero-degree="handleZeroDegree"
            @rotate-right="handleRotateRight"
            @align-close="handleCloseObjects"
            @align-to-left="handleAlignToLeft"
            @align-to-right="handleAlignToRight"
            @align-to-top="handleAlignToTop"
            @align-to-bottom="handleAlignToBottom"
            @align-to-center-vertical="handleAlignToCenterVertical"
            @align-to-center-horizontal="handleAlignToCenterHorizontal"
            @line-size-selected="handleLineSizeSelected"
            @color-selected="onColorPickerClick"
        />
      </div>
      <div class="left-panel">
        <LeftPanel
            :project-name="projectName"
            :contentTitle="buttonTitleFromTopPanel"
            :content-index="buttonIndexFromTopPanel"
            @clear-clipboard="clearClipboard"
        />

      </div>
      <div class="middle-panel">
        <CanvasComponent ref="canvasComponent"
                         @update-message="updateMessage"
                         @canvas-created="setCanvas"
                         @viewport-updated="updateViewport"
                         @padlock-updated="updatePadlock"
                         @selections-flag-updated="updateSelectionsFlag"
                         @selected-object-updated="updateSelectedObject"
                         @group-creation-updated="updateGroupCreation"
                         @project-name="setProjectName"
                         @add-new-button="addNewButton"
                         @reset-buttons="resetAllDrawingButtons"
        />

        <WindowComponent title="Notatka powiązana z obiektem" :show="isMessageVisible" @update:show="updateMessage" >
          <template v-slot:default>
            <div v-html="messageFromCanvas" class="message-active"></div>
          </template>
        </WindowComponent>
      </div>
      <div class="right-panel">
        <RightPanel
            :myCanvas="myCanvas"
            :viewportWidth="viewportWidth"
            :viewportHeight="viewportHeight"
            :isGlobalSelectOpen="isGlobalSelectOpen"
            :padlockButtonDisabled="padlockButtonDisabled"
            @move-active="handleMoveActive"
            @change-selectable-for-objects="changeSelectableForObjects"
        />
      </div>
      <div class="bottom-left-panel">
        <a class="my-link" href="http://itechmind.pl" target="_blank" rel="noopener noreferrer">Developed by ITechMind</a>
      </div>
      <div class="bottom-right-panel">

      </div>
<!--      <div class="bottom-panel">-->
<!--        <div class="version-container">-->
<!--          ElectroDraw Wersja {{ app_version}} Wszystkie prawa zastrzeżone. Incobex {{ getCurrentYear() }}.-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import ToolbarComponent from '../components/ToolbarComponent.vue';
import LeftPanel from "../components/LeftPanel.vue";
import CanvasComponent from '../components/CanvasComponent.vue';
import DrawingControls from '../components/DrawingControls.vue';
import canvasComponent from "@/components/CanvasComponent.vue";
import RightPanel from "@/components/RightPanel.vue";
import ToolsPanel from "@/components/ToolsPanel.vue";
import WindowComponent from "@/components/WindowComponent.vue";
import TabComponent from "@/components/TabComponent.vue";
import {CANVAS_SIZES, TOP_TABS} from "@/utils/constants";

export default {
  name: 'HomeView',
  computed: {
    canvasComponent() {
      return canvasComponent
    },
    isMessageVisible() {
      return this.messageFromCanvas.length > 0;
    },
    groupsWithProps() {
      return this.groups.map(group => {
        let props = {};

        if (group.name === 'WARSTWY') {
          props = {
            activeIndex: this.buttonIndexFromTopPanel
          };
        } else if (group.name === 'KSZTAŁTY') {
          props = {
            message: this.messageFromCanvas
          };
        } else if (group.name === 'RYSUNKI') {
          props = {
            drawings: this.drawings,
          };
        }

        return { ...group, props };
      });
    }
  },
  emits: ['updateMessage', 'updateTitle', 'showDrawing'],
  components: {
    TabComponent,
    WindowComponent,
    ToolsPanel,
    RightPanel,
    LeftPanel,
    ToolbarComponent,
    CanvasComponent,
    DrawingControls
  },
  data() {
    return {
      myCanvas: null,
      projectName: "Projekt",
      selectedObjects: null,
      viewportWidth: CANVAS_SIZES.WIDTH,  // for A3 300dpi
      viewportHeight: CANVAS_SIZES.HEIGHT,
      isGlobalSelectOpen: false,
      padlockButtonDisabled: false,
      isSelected: false,
      isSelectedOne: false,
      groupCreationDisabled: true,
      drawings: [],
      messageFromCanvas: !this.$layout.isDevMenu? '' : 'Tu pojawi się notatka dopisana do zaznaczonego obiektu. <br><br> <hr><br> <span class="simple-message">Możesz ustawić to okno w dowolnym miejscu chwytając za jego pasek tytułowy. Będzie ono pojawiać się tylko po kliknięciu w obiekt z notatką.</span>',
      buttonTitleFromTopPanel: 'OBUDOWY',
      buttonIndexFromTopPanel: 0,
      drawingIndexFromBottomPanel: 0,
      app_version: window.app_version ? window.app_version : "0.0.0",
      groups: [
        {
          name: 'WARSTWY',
          ref: 'layers',
          component: 'TabComponent',
          isActive: true,
          events: {
            'update-title': this.updateTitleAndIndex,
          }
        },
        {
          name: 'KSZTAŁTY',
          ref: 'shapes',
          component: 'ToolbarComponent',
          isActive: this.$layout.isDevMenu,
          events: {
            'update-message': this.updateMessage,
          }
        },
        {
          name: 'RYSUNKI',
          ref: 'drawings',
          component: 'DrawingControls',
          isActive: this.$layout.isDevMenu,
          events: {
            'show-drawing': this.updateDrawingIndex,
            'new-drawing-name': this.updateDrawingName,
          }
        }
      ],
      maxActiveWidgets: this.$layout.menuSize,
    };
  },
  mounted() {
    // Initialize drawings array with empty arrays for each drawing
    this.drawings = Array.from({ length: 1 }, () => []);
  },
  watch: {
    '$layout.isDevMenu': {
      handler() {
        for(let i=0; i<this.groups.length; i++) {
          this.groups[i].isActive = this.$layout.isDevMenu;
        }

        this.groups[0].isActive = true;
      },
      immediate: true
    }
  },
  methods: {
    toggleWidget(index) {
      if (this.groups[index].isActive) {
        if (this.$layout.isDevMenu) {
          this.groups[index].isActive = false;
        }
      } else {
        const activeWidgets = this.groups.filter(group => group.isActive);
        if (activeWidgets.length >= this.maxActiveWidgets) {
          const oldestActiveIndex = this.groups.findIndex(group => group.isActive);
          this.groups[oldestActiveIndex].isActive = false;
        }
        this.groups[index].isActive = true;

      }
    },
    setCanvas(canvas) {
      this.myCanvas = canvas;
    },
    setProjectName(name) {
      this.projectName = name;
    },
    resetAllDrawingButtons() {
      this.$refs.drawings[0].resetAllButtons();
    },
    clearClipboard() {
      this.$refs.toolPanel.clearClipboard()
    },
    addNewButton(canvasIndex, buttonName){
      this.$refs.drawings[0].addNewButton(canvasIndex, buttonName);
    },
    updateViewport(args) {
      this.viewportWidth = args.width;
      this.viewportHeight = args.height;
    },
    updatePadlock(args) {
      this.isGlobalSelectOpen = args.isGlobalSelectOpen;
      this.padlockButtonDisabled = args.padlockButtonDisabled;
    },
    updateSelectionsFlag(args) {
      this.isSelected = args.isSelected;
      this.isSelectedOne = args.isSelectedOne;
    },
    updateSelectedObject(objects) {
      this.selectedObjects = objects;
    },
    updateGroupCreation(flag) {
      this.groupCreationDisabled = flag;
    },
    handleMoveActive(flag) {
      this.$refs.canvasComponent.handleMoveActive(flag);
    },
    changeSelectableForObjects(flag) {
      this.$refs.canvasComponent.changeSelectableForObjects(flag)
    },
    updateMessage(newMessage) {
      this.messageFromCanvas = newMessage;
    },
    updateTitleAndIndex(newTitle, newIndex, lastActive) {
      this.buttonTitleFromTopPanel = newTitle;
      this.buttonIndexFromTopPanel = newIndex;
      this.updateActiveIndex(lastActive);
      if (newIndex === TOP_TABS.MAX_VISIBLE_TABS - 1) {
        this.$refs.canvasComponent.changeSelectableForObjects(false);
      }
    },
    updateDrawingName(activeDrawingName) {
      this.$refs.canvasComponent.updateDrawingName(activeDrawingName);
    },
    updateDrawingIndex(index, activeDrawingName, deepCopy) {
      this.$refs.canvasComponent.updateDrawingIndex(index, activeDrawingName, deepCopy);
    },
    updateActiveIndex(lastActive) {
      this.$refs.canvasComponent.updateActiveIndex(this.buttonIndexFromTopPanel, lastActive);
    },
    handleAddNote() {
      this.$refs.canvasComponent.handleAddNote();
    },
    handleRotateLeft() {
      this.$refs.canvasComponent.handleRotateLeft();
    },
    handleZeroDegree() {
      this.$refs.canvasComponent.handleZeroDegree();
    },
    handleRotateRight() {
      this.$refs.canvasComponent.handleRotateRight();
    },
    handleCloseObjects() {
      this.$refs.canvasComponent.handleCloseObjects();
    },
    handleAlignToLeft() {
      this.$refs.canvasComponent.handleAlignToLeft();
    },
    handleAlignToRight() {
      this.$refs.canvasComponent.handleAlignToRight();
    },
    handleAlignToTop() {
      this.$refs.canvasComponent.handleAlignToTop();
    },
    handleAlignToBottom() {
      this.$refs.canvasComponent.handleAlignToBottom();
    },
    handleAlignToCenterVertical() {
      this.$refs.canvasComponent.handleAlignToCenterVertical();
    },
    handleAlignToCenterHorizontal() {
      this.$refs.canvasComponent.handleAlignToCenterHorizontal();
    },
    handleLineSizeSelected(size) {
      this.$refs.canvasComponent.handleLineSizeSelected(size);
    },
    onColorPickerClick(color) {
      this.$refs.canvasComponent.onColorPickerClick(color);
    }
  }
};
</script>
<style lang="scss">
@import '@/assets/themes.css';

hr {
  width: 94%;
  border-width: var(--border-width);
  border-color: var(--thin-border-color)
}
.container {
  display: grid;
  min-width: 356px;
  grid-template-rows: 120px 1fr 35px;
  grid-template-columns: 210px minmax(100px, 1fr) 46px;
  height: 100vh;
}

.top-panel {
  grid-row: 1 / 2;
  grid-column: 2 / 4;
  background-color: var(--primary-background);
}

.group-container {
  position: relative;
  display: flex;
  overflow-x: hidden;
  width: 100%;
  max-width: calc(100vw - 210px);
  padding-left: 10px;
  margin-bottom: 1px;
  border-left: var(--buttons-border-width) solid var(--thin-border-color);
  border-bottom: var(--buttons-border-width) solid var(--thin-border-color);
}

.group-container.dev {
  //align-items: center;
  //justify-content: center;
  padding-left: 120px;
}

.group {
  display: flex;
  flex-shrink: 0;
  height: 80px;
}

.vertical-button {
  writing-mode: vertical-rl;
  text-orientation: upright;
  padding: 2px 6px;
  height: 80px;
  cursor: pointer;
  color: var(--primary-text-color);
  background-color: var(--tab-buttons-color);
  border: var(--buttons-border-width) solid var(--buttons-border-color);
  font-size: 8px;
  margin-right: 5px;
}

.vertical-button.active {
  color: var(--inverse-text-color);
  background-color: var(--buttons-active-color);
}

.vertical-button.horizontal {
  position: absolute;
  left: 5px;
  writing-mode: horizontal-tb;
  padding: 2px;
  height: 24px;
  width:80px;
  margin-top: 1px;
  border-radius: var(--buttons-border-radius);
  font-size: 12px;
}

.vertical-button.horizontal.btn1 {
  top: 26px;
}

.vertical-button.horizontal.btn2 {
  top: 52px;
}

.vertical-button.active.horizontal {
  color: var(--inverse-text-color);
  background-color: var(--buttons-active-color);
}

.widget {
  width: 100%;
  padding: 5px;
  margin-right: 5px;
}

.top-left-panel {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  font-weight: bold;
  padding: 10px;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: var(--primary-background);
  //background: var(--logo-background);
  border-right: var(--border-width) solid var(--border-color);
}

.top-left-background {
  //border-radius: var(--border-radius);
  //background-color: #FFF;
  height: 80px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.bottom-left-panel {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  padding: 5px;
  background-color: var(--primary-background);
  border-right: var(--border-width) solid var(--border-color);
}

 .bottom-right-panel {
   grid-row: 3 / 4;
   grid-column: 3 / 4;
   background-color: var(--primary-background);
   //border-left: var(--border-width) solid var(--border-color);
 }

.bottom-panel {
  background-color: var(--secondary-background);
  color: white;
}

.left-panel {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  color: var(--primary-text-color);
  background-color: var(--primary-background);
  border-right: var(--border-width) solid var(--border-color);
}

.middle-panel {
  grid-row: 2 / 4;
  grid-column: 2 / 3;
  background-color: var(--canvas-background);
}

.right-panel {
  grid-row: 2 / 3;
  grid-column: 3 / 4;
  background-color: var(--primary-background);
  //border-left: var(--border-width) solid var(--border-color);
  //border-top: var(--border-width) solid var(--border-color);
}

.right-text {
  display: flex;
  justify-content: flex-start;
  color: var(--primary-text-color);
  margin-left: 20px;
  margin-top: 20px;
  font-style: italic;
}

.version-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 5px;
}

.my-link {
  text-decoration: none;
  font-size: 15px;
  font-style: italic;
  color: var(--light-gray-color);
}

#logo {
  margin: 10px;
  width: 150px;
  height: auto;
}

.message-active {
  color: var(--important-text-color);
}

.simple-message {
  color: var(--primary-text-color);
}
</style>