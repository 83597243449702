export const CANVAS_SIZES = {
    WIDTH: 7016,
    HEIGHT: 4961,
};

export const PRINT_SIZES = {
    WIDTH: 594,
    HEIGHT: 420,
}

export const APP_SETTINGS = {
    APP_NAME: 'inBOX Creator',
    VERSION: '0.9.1',
};

export const TOP_TABS = {
    MAX_VISIBLE_TABS: 5,
}