import { fabric } from 'fabric';

// Measure
// eslint-disable-next-line no-unused-vars
export function createMeasure(left, top, color, stroke) {
    const measurePath = new fabric.Path('M 0 0 L 0 10 M 0 5 L 590.6 5 M 590.6 0 L 590.6 10', {
        left: left,
        top: top,
        fill: '',
        stroke: 'black',
        strokeWidth: 2,
        strokeUniform: true,
        selectable: false,
        hasBorders: true,
        hasControls: true,
        lockScalingY: true,
        originX: 'left',
        originY: 'top'
    });

    const text = new fabric.Text('25.0', {
        left: left+290,
        top: top-27,
        stroke: 'black',
        fill: 'black',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'italic',
        fontSize: 30,
        strokeWidth: 0.1,
        strokeUniform: true,
        lockScalingY: true,
        selectable: false,
        evented: false,
        objectCaching: false,
    });

    const measurePathGroup = new fabric.Group([measurePath, text], {
        left: left-295,
        top: top,
        selectable: true,
        hasBorders: true,
        hasControls: true,
        lockScalingY: true,
        originX: 'left',
        originY: 'top',
        name: 'Measure',
        objectCaching: false,
    });

    measurePathGroup.setControlsVisibility({
        mt: false, // Top middle
        mb: false, // Bottom middle
        ml: true,  // Middle left
        mr: true,  // Middle right
        tl: false, // Top left
        tr: false, // Top right
        bl: false, // Bottom left
        br: false  // Bottom right
    });

    return measurePathGroup;
}

// Simple line
export function createSimpleLine(left, top, color, stroke) {
    const arrowPath = new fabric.Path('M 0 0 L 200 0', {
        left: left-100,
        top: top,
        fill: '',
        stroke: color,
        strokeWidth: stroke,
        strokeUniform: true,
        selectable: true,
        hasBorders: true,
        hasControls: true,
        lockScalingY: true,
        originX: 'left',
        originY: 'top',
        name: 'Line',
    });

    arrowPath.setControlsVisibility({
        mt: false, // Top middle
        mb: false, // Bottom middle
        ml: true,  // Middle left
        mr: true,  // Middle right
        tl: false, // Top left
        tr: false, // Top right
        bl: false, // Bottom left
        br: false  // Bottom right
    });

    return arrowPath;
}

// Arrow
export function createArrow(left, top, color, stroke) {
    const arrowPath = new fabric.Path('M 0 0 L 199 0 M 196 -3 L 200 0 L 196 3', {
        left: left-100,
        top: top,
        fill: '',
        stroke: color,
        strokeWidth: stroke,
        strokeUniform: true,
        selectable: true,
        hasBorders: true,
        hasControls: true,
        lockScalingY: true,
        originX: 'left',
        originY: 'top',
        name: 'Arrow'
    });

    arrowPath.setControlsVisibility({
        mt: false, // Top middle
        mb: false, // Bottom middle
        ml: true,  // Middle left
        mr: true,  // Middle right
        tl: false, // Top left
        tr: false, // Top right
        bl: false, // Bottom left
        br: false  // Bottom right
    });

    return arrowPath;
}

// Line curve
// export function createQuadraticBezierCurve(left, top, color, stroke, canvas) {
//     const start = { x: left-100, y: top };
//     const control = { x: left, y: top };
//     const end = { x: left+100, y: top };
//
//     const pathData = `M ${start.x} ${start.y} Q ${control.x} ${control.y} ${end.x} ${end.y}`;
//
//     const bezierCurve = new fabric.Path(pathData, {
//         fill: '',
//         stroke: color,
//         strokeWidth: stroke,
//         selectable: false,
//         evented: false,
//         objectCaching: false,
//         lockSelectability: true,
//         name: 'BezierCurve',
//     });
//
//     const startPoint = new fabric.Circle({
//         left: start.x,
//         top: start.y,
//         radius: 5,
//         fill: null,
//         stroke: color,
//         strokeWidth: 1,
//         originX: 'center',
//         originY: 'center',
//         hasBorders: false,
//         hasControls: false,
//         pointType: 'start',
//         selectable: true,
//         evented: true,
//         name: 'handler',
//     });
//
//     const controlPoint = new fabric.Circle({
//         left: control.x,
//         top: control.y,
//         radius: 5,
//         fill: null,
//         stroke: color,
//         strokeWidth: 1,
//         originX: 'center',
//         originY: 'center',
//         hasBorders: false,
//         hasControls: false,
//         pointType: 'control',
//         selectable: true,
//         evented: true,
//         name: 'handler',
//     });
//
//     const endPoint = new fabric.Circle({
//         left: end.x,
//         top: end.y,
//         radius: 5,
//         fill: null,
//         stroke: color,
//         strokeWidth: 1,
//         originX: 'center',
//         originY: 'center',
//         hasBorders: false,
//         hasControls: false,
//         pointType: 'end',
//         selectable: true,
//         evented: true,
//         name: 'handler',
//     });
//
//     const updatePath = () => {
//         const newPathData = [
//             ['M', startPoint.left, startPoint.top],
//             ['Q', controlPoint.left, controlPoint.top, endPoint.left, endPoint.top]
//         ];
//         bezierCurve.set({ path: newPathData });
//         bezierCurve.setCoords();
//         canvas.renderAll();
//     };
//
//     [startPoint, controlPoint, endPoint].forEach(point => {
//         point.on('moving', updatePath);
//         point.on('deselected', updatePath);
//     });
//
//     return { bezierCurve, startPoint, controlPoint, endPoint };
// }

// Line curve
export function createQuadraticBezierCurve(left, top, color, stroke) {
    const start = { x: left-100, y: top-100 };
    const control = { x: left-100, y: top+100 };
    const end = { x: left+100, y: top+100 };

    const pathData = `M ${start.x} ${start.y} Q ${control.x} ${control.y} ${end.x} ${end.y}`;

    return new fabric.Path(pathData, {
        fill: '',
        stroke: color,
        strokeWidth: stroke,
        selectable: true,
        evented: true,
        strokeUniform: true,
        name: 'Curve',
    });
}

export function createEllipse(left, top, color, stroke, filled=false) {
    const fill = filled ? color : null;
    const _stroke = filled ? 0 : stroke;
    const width = 60
    const height = 40
    return new fabric.Ellipse({
        left: left - width,
        top: top - height,
        fill: fill,
        stroke: color,
        strokeWidth: _stroke,
        strokeUniform: true,
        rx: width,
        ry: height,
        originX: 'left',
        originY: 'top',
        name: 'Ellipse',
    });
}

export function createRectangle(left, top, color, stroke, filled=false) {
    const fill = filled ? color : null;
    const _stroke = filled ? 0 : stroke;
    const width = 120
    const height = 80
    return new fabric.Rect({
        left: left - width / 2,
        top: top - height / 2,
        fill: fill,
        stroke: color,
        strokeWidth: _stroke,
        strokeUniform: true,
        originX: 'left',
        originY: 'top',
        width: width,
        height: height,
        name: 'Rectangle',
    });
}
