<template>
  <div class="my-container">
    <h1 class="title">{{ title }}</h1>
    <CustomDropdown
        ref="customDropdown"
        :options="dropDownList"
        placeholder="Wybierz grupę"
        backgroundColor="#f0f0f0"
        textColor="#333333"
        borderColor="#999999"
        fontSize="14px"
        width="184px"
        @selected="handleSelection"
    />
    <input
        ref="searchInput"
        type="search"
        v-model="searchQuery"
        placeholder="Wyszukaj po nazwie"
        class="search-input"
        @focus="clearClipboard"
    />
    <div class="content" ref="contentDiv">
      <div v-if="filteredIcons.length === 0" class="loading-message">
        Trwa<br />ładowanie<br />aparatury
      </div>
      <div v-for="icon in filteredIcons"
           :key="icon"
           draggable="true"
           @dragstart="onDragStart($event, icon)"
           class="icon-figures">
        <figure class="icon-figure">
          <img :src="'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(icon.svg_string)" :alt="icon.name" />
          <figcaption>{{ icon.name }}</figcaption>
        </figure>

      </div>
    </div>
  </div>
</template>

<script>
import CustomDropdown from "@/components/CustomDropDown.vue";

export default {
  components: {CustomDropdown},
  props: {
    title: {
      type: String,
      required: true,
    },
    icons: {
      type: Array,
      required: true,
    },
    sections: {
      type: Array,
      required: true,
    },
    iconsSetIndex: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      showByGroup: null,
      actualSection: -1,
      searchQuery: '',
      dropdownOptions: [],
    };
  },
  mounted() {
    console.log('Component mounted.');
  },
  watch: {
    iconsSetIndex: {
      immediate: true,
      handler() {
        if (this.$refs.customDropdown) {
          this.$refs.customDropdown.reset();
        }
      },
    },
  },
  computed: {
    filteredIcons() {
      if (this.iconsSetIndex < this.icons.length && typeof this.icons[this.iconsSetIndex].filter === 'function') {
        const { icons, iconsSetIndex, searchQuery, actualSection, showByGroup } = this;
        const lowercaseQuery = searchQuery.toLowerCase();

        return icons[iconsSetIndex].filter(({ name, not_visible, section }) => {
          const baseCondition = name.toLowerCase().includes(lowercaseQuery) && !not_visible;
          return showByGroup ? baseCondition && section === actualSection : baseCondition;
        });
      } else {
        return [];
      }
    },
    dropDownList() {
      if (this.iconsSetIndex < this.sections.length && typeof this.sections[this.iconsSetIndex].filter === 'function') {
        return this.sections[this.iconsSetIndex];
      } else {
        return [];
      }
    },
  },
  methods: {
    clearClipboard() {
      this.$emit('clearClipboard');
    },
    // eslint-disable-next-line no-unused-vars
    handleSelection(value, name) {
      this.showByGroup = value === -1? null : value;
      this.actualSection = value;
      this.searchQuery = '';
      this.$refs.contentDiv.scrollTop = 0;
    },
    onDragStart(event, icon) {
      // const src = event.target.src;
      const svg = icon.svg_string;
      const name = icon.name;
      const editable_hor = icon.editable_horizontal;
      const editable_ver = icon.editable_vertical;
      const rotation = icon.free_rotation;

      let json;
      if (icon.attach) {
        // const attach_src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(icon.attach.svg_string);
        const attach_svg = icon.attach.svg_string;
        const attach_name = icon.attach.name;
        const attach_editable_h = icon.attach.editable_h;
        const attach_editable_v = icon.attach.editable_v;
        const attach_rotation = icon.attach.rotation;
        const attach_group = icon.attach.group;
        json = JSON.stringify({svg, name, editable_hor, editable_ver, rotation, attach_svg, attach_name, attach_editable_h, attach_editable_v, attach_rotation, attach_group})
      } else {
        json = JSON.stringify({svg, name, editable_hor, editable_ver, rotation});
      }
      event.dataTransfer.setData('text/plain', json);
    },
  },
};
</script>

<style scoped>
@import '@/assets/themes.css';

.my-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin: 0;
  font-size: 16px;
  padding: 8px 0;
  color: var(--important-text-color);
}

.search-input {
  display: block;
  width: 184px;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 10px;
  background-color: var(--component-background);
  border: var(--buttons-border-width) solid var(--component-border-color);
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 20px;
  margin-left: 2px;
  min-height: 180px;
  max-height: calc(100vh - 580px);
  width: 184px;
  padding: 10px 5px;
  background: var(--component-background);
  border-radius: var(--border-radius);
  border: var(--buttons-border-width) solid var(--component-border-color);
}

.content::-webkit-scrollbar {
  width: 1px;
}

.content::-webkit-scrollbar-thumb {
  background-color: var(--buttons-color);
  border-radius: var(--border-radius);
}

.content::-webkit-scrollbar-track {
  background: var(--primary-background);
  border-radius: var(--border-radius);
}

.loading-message {
  font-size: 20px;
  line-height: 1.5;
  width: 184px;
}

.icon-figures {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-figure {
  margin: 0;
  width: 75px;
  text-align: center;
  cursor: grab;
}

.icon-figure img {
  width: 100%;
  height: auto;
  display: block;
  max-height: 80px;
  object-fit: contain;
}

.icon-figure figcaption {
  margin-top: 5px;
  font-size: 14px;
}

</style>
