import { jsPDF } from 'jspdf';
import 'svg2pdf.js'
import html2canvas from 'html2canvas';
import {fItalic, fRegular, fLight, fLightItalic} from './encoded_fonts'
import {CANVAS_SIZES, PRINT_SIZES} from "@/utils/constants";

export async function generateA3SVG(canvasFabric, projectName = 'document', download = true) {
    try {
        const svgString = canvasFabric.toSVG();
        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(svgString, 'image/svg+xml');
        const svgElement = svgDocument.documentElement;

        const styleElement = svgDocument.createElement('style');
        styleElement.textContent = `
          @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
          text {
            font-family: 'Open Sans', sans-serif;
          }
        `;

        svgElement.insertBefore(styleElement, svgElement.firstChild);

        const svgWidth = `${PRINT_SIZES.WIDTH}mm`;
        const svgHeight = `${PRINT_SIZES.HEIGHT}mm`;

        svgElement.setAttribute('width', svgWidth);
        svgElement.setAttribute('height', svgHeight);
        svgElement.setAttribute('viewBox', `0 0 ${CANVAS_SIZES.WIDTH} ${CANVAS_SIZES.HEIGHT}`);

        // Konwertuj zaktualizowany SVG do stringa
        const serializer = new XMLSerializer();
        const updatedSvgString = serializer.serializeToString(svgElement);

        if (download) {
            // Pobieranie pliku SVG
            const blob = new Blob([updatedSvgString], { type: 'image/svg+xml;charset=utf-8' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${projectName}.svg`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } else {
            // Otwieranie pliku SVG w nowej zakładce
            const newWindow = window.open();
            newWindow.document.write('<pre>' + updatedSvgString + '</pre>');
            newWindow.document.close();
        }
    } catch (error) {
        console.error('Error generating SVG:', error);
    }
}

// eslint-disable-next-line no-unused-vars
export async function generateA3PDF(canvasDOM, canvasFabric, viewportWidth, viewportHeight, projectName = 'document', download = true) {
    const svgString = canvasFabric.toSVG();
    const parser = new DOMParser();
    const svgDocument = parser.parseFromString(svgString, 'image/svg+xml');
    const svgElement = svgDocument.documentElement;

    const styleElement = svgDocument.createElement('style');
    styleElement.textContent = `
          @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
          text {
            font-family: 'Open Sans', sans-serif;
          }
        `;

    svgElement.insertBefore(styleElement, svgElement.firstChild);

    // Tworzenie nowego dokumentu PDF
    const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a2'
    });

    // Dodanie czcionki Montserrat do PDF
    pdf.addFileToVFS("OpenSans.ttf", fRegular);
    pdf.addFont("OpenSans.ttf", "Open Sans", "normal", "normal");
    pdf.addFileToVFS("OpenSans-Light.ttf", fLight);
    pdf.addFont("OpenSans-Light.ttf", "Open Sans", "normal", "300");
    pdf.addFileToVFS("OpenSans-Italic.ttf", fItalic);
    pdf.addFont("OpenSans-Italic.ttf", "Open Sans", "italic", "normal");
    pdf.addFileToVFS("OpenSans-LightItalic.ttf", fLightItalic);
    pdf.addFont("OpenSans-LightItalic.ttf", "Open Sans", "italic", "300");
    pdf.setFont("Open Sans");


    // Rozmiar strony PDF
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Rozmiary SVG
    const svgWidth = CANVAS_SIZES.WIDTH;
    const svgHeight = CANVAS_SIZES.HEIGHT;

    // Oblicz skalę
    const scaleX = pdfWidth / svgWidth;
    const scaleY = pdfHeight / svgHeight;
    const scale = Math.min(scaleX, scaleY);

    // Konwertuj SVG do PDF z wbudowaną czcionką Montserrat
    pdf
        .svg(svgElement, {
            x: 0,
            y: 0,
            width: svgWidth * scale,
            height: svgHeight * scale
        })
        .then(() => {
            pdf.save(`${projectName}.pdf`);
        });
}

// export async function generateA3PDF(canvasDOM, canvasFabric, viewportWidth, viewportHeight, projectName='document', download = true) {
//     // Konfiguracja html2canvas
//     const options = {
//         scale: 1, // Ustaw skalowanie na 1
//         width: viewportWidth,
//         height: viewportHeight,
//         useCORS: true,
//         willReadFrequently: true,
//     };
//
//     // Generowanie obrazu z canvasu
//     try {
//         const canvasImage = await html2canvas(canvasDOM, options);
//         const imgData = canvasImage.toDataURL('image/png');
//
//         const pdf = new jsPDF('landscape', 'mm', 'a2');
//
//         const a3Width = PRINT_SIZES.WIDTH;
//         const a3Height = PRINT_SIZES.HEIGHT;
//
//         pdf.addImage(imgData, 'PNG', 0, 0, a3Width, a3Height, '', "FAST");
//
//         if (download) {
//             pdf.save(`${projectName}.pdf`);
//         } else {
//             window.open(pdf.output('bloburl'), '_blank');
//         }
//     } catch (error) {
//         console.error('Error generating PDF:', error);
//     }
// }

export async function generateA4PDF(canvasDOM, canvasFabric, viewportWidth, viewportHeight, projectName='document', download = true) {
    const options1 = {
        scale: 1,
        width: viewportWidth/2,
        height: viewportHeight,
        useCORS: true,
        willReadFrequently: true,
    };
    const options2 = {
        scale: 1,
        width: viewportWidth/2,
        height: viewportHeight,
        useCORS: true,
        x: viewportWidth / 2,
        willReadFrequently: true,
    };

    try {
        const canvasImage1 = await html2canvas(canvasDOM, options1);
        const canvasImage2 = await html2canvas(canvasDOM, options2);
        const imgData1 = canvasImage1.toDataURL('image/png');
        const imgData2 = canvasImage2.toDataURL('image/png');

        const pdf = new jsPDF('portrait', 'mm', 'a3');

        const a4Width = 297;
        const a4Height = 420;

        pdf.addImage(imgData1, 'PNG', 0, 0, a4Width, a4Height);

        pdf.addPage();
        pdf.addImage(imgData2, 'PNG', 0, 0, a4Width, a4Height);

        if (download) {
            pdf.save(`${projectName}.pdf`);
        } else {
            window.open(pdf.output('bloburl'), '_blank');
        }
    } catch (error) {
        console.error('Error generating PDF:', error);
    }
}

function checkObjectName(name) {
    switch(name) {
        case 'Ellipse':
            return "Elipsa";
        case 'Rectangle':
            return 'Prostokąt';
        case 'Measure':
            return 'Miara';
        case 'BezierCurve':
            return "Krzywa";
        case 'handler':
            return 'Krzywa';
        case 'Arrow':
            return 'Strzałka';
        case 'Line':
            return "Linia";
        default:
            return name;
    }
}

export function generateNotesTable(canvas) {
    const objects = canvas.getObjects();

    const notes = objects.filter(obj => obj.note).map((obj, index) => ({
        lp: index + 1,
        name: checkObjectName(obj.name) || 'Brak nazwy',
        note: obj.note
    }));

    let tableHtml = `
    <table border="1">
      <thead>
        <tr>
          <th>Lp</th>
          <th>Nazwa elementu</th>
          <th>Notatka</th>
        </tr>
      </thead>
      <tbody>
        ${notes.map(note => `
          <tr>
            <td>${note.lp}</td>
            <td>${note.name}</td>
            <td>${note.note}</td>
          </tr>`).join('')}
      </tbody>
    </table>`;

    const newWindow = window.open('', '_blank');
    newWindow.document.write(`
    <html>
      <head>
        <title>Notatki z canvasu</title>
      </head>
      <body>
        ${tableHtml}
        <button onclick="window.print()">Drukuj</button>
        <button onclick="window.close()">Zamknij</button>
      </body>
    </html>`);
    newWindow.document.close();
}

// Funkcja do eksportu danych do CSV
// function exportToCSV(notes) {
//     const csvContent = 'Lp,Nazwa elementu,Notatka\n' +
//         notes.map(note => `${note.lp},${note.name},${note.note}`).join('\n');
//
//     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.download = 'notatki.csv';
//     link.click();
// }